@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker.css';

#root {
  @apply h-screen w-screen;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply text-gray-900;
}

:focus-visible,
:focus {
  outline: 0;
}

[type='text']:focus {
  --tw-ring-color: 'transparent';
}

.no-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.table-with-action tbody tr .action-btn {
  @apply invisible;
}

.table-with-action tbody tr:hover .action-btn {
  @apply visible;
}

.div-with-action .action-btn {
  @apply invisible;
}

.div-with-action:hover .action-btn {
  @apply visible;
}

form {
  @apply text-sm;
}

.ck-editor__editable {
  min-height: 500px;
  padding: 0 16px !important;
}

.ck-editor__editable ul {
  margin-left: 24px !important;
}

.ck-editor__editable ol {
  margin-left: 24px !important;
}
